<template>
  <div class="flex bg header">
    <md-icon name="arrow-left" size="lg" v-if="icon" @click="goBack"></md-icon>
    <div :class="icon ? '' : 'flex-center'">{{ title }}</div>
    <div> </div>
  </div>
</template>

<script>
export default {
  name: "TitleHeader",
  props: {
    title: {
      type: String,
      default: ''
    },
    icon: {
      type: Boolean,
      default: true
    }
  },
  mounted() {
    document.title = this.title
  },
  methods: {
    goBack() {
      this.$router.back();
    }
  }
}
</script>

<style lang="less" scoped>
.header {
  font-size: 0.45rem;
  padding: 0.35rem 0.25rem;
  justify-content: space-between;
  align-items: center;
}
</style>
