<template>
  <div class="full-container">
    <title-header :title="'平板锁ID删除'"/>

    <div class="setting-container">
      <p class="setting-tips">请扫描或输入您需要删除的锁号：</p>
      <div class="flex-no-wrap fields-item">
        <md-icon name="scan" size="lg" @click="controlScanModal(true)"></md-icon>
        <input type="number" class="fields-input" v-model="lockNo">
      </div>
    </div>
    <scan v-if="isScan" @closeScan="controlScanModal(false)" @getCode="getScanCode"/>
    <md-button type="warning" class="remove-btn" round @click="confirmRemove">删除</md-button>
  </div>
</template>

<script>
import TitleHeader from "../../components/TitleHeader";
import Scan from "../../components/Scan";
import {Dialog, Toast} from "mand-mobile";
import axios from "axios";
import ApiConfig from "../../assets/config/api.config";

export default {
  name: "remove",
  components: {
    TitleHeader,
    Scan
  },
  data() {
    return {
      isScan: false,
      lockNo: ''
    }
  },
  methods: {
    // 控制config Modal的显示
    controlScanModal(bool) {
      this.isScan = bool;
    },
    getScanCode(code) {
      this.lockNo = code;
    },
    confirmRemove() {
      if(!this.lockNo) {
        Toast.info('请扫描或输入您需要删除的锁号');
        return false;
      }
      Dialog.confirm({
        title: '确认',
        content: '确认是否要删除锁号：' + this.lockNo,
        confirmText: '确定',
        onConfirm: () => this.removeLock(),
      })
    },
    removeLock() {
      let params = {
        lockNo: this.lockNo
      }
      axios.post(ApiConfig.removeSn, params).then(res => {
        if(res.data.code === 200) { // 接口状态
          if(res.data.data.StatusCode === 200) {
            Toast.info('删除锁号：'+this.lockNo + '成功');
            this.lockNo = '';
          }else {
            Toast.info(res.data.data.Message);
          }
        }else {
          Toast.info(res.data.msg);
        }
      }).catch(err => {
        if(err.response.status === 401) {
          Toast.info("请重新登录");
          this.$router.push({path: '/login'});
        }
      })
    }

  }
}
</script>

<style lang="less" scoped>
.setting-container {
  padding: 1rem 0.5rem;
  .setting-tips {
    margin-bottom: 0.5rem;
    color: #666;
    font-size: 0.5rem;
    width: 100% !important;
  }
  .fields-item {
    justify-content: space-between;
    .md-icon.icon-font.lg {
      font-size: 1.2rem;
    }
    .fields-input {
      width: 80%;
      padding: 0.2rem 0.3rem;
      font-size: 0.5rem;
    }
  }
}

.remove-btn {
  width: 90%;
  margin: 1rem auto;
}

</style>
